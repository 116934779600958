/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import jQuery from 'jquery'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
// import ProtoContent from 'components/Diensten/ProtoContent'
// import CTABanner from 'components/Common/CTABanner'
import FilterCategory from 'components/Common/FilterCategory'
import PriceFilter from 'components/Common/PriceFilter'
import SwitchFilter from 'components/Common/SwitchFilter'
import RangeFilter from 'components/Common/RangeFilter'
import SingleHome from 'components/Aanbod/SingleHome'
import { Paragraph } from 'components/StyledComponents'

// Third Party
import styled from 'styled-components'

// const windowGlobal = typeof window !== 'undefined' && window

const Aanbod = styled.div``

const LoadMoreContainer = styled.div`
  ${Paragraph} {
    cursor: pointer;
    transition: 0.25s;
  }

  ${Paragraph} {
    :hover {
      transform: scale(1.05);
    }
  }
`

const InnerFilters = styled.div`
  border-radius: 5px;
`

const Filters = styled.div`
  @media (max-width: 991px) {
    position: fixed !important;
    left: -100%;
    z-index: 2312312312312312 !important;
    top: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 35px;
    height: 100%;
    width: 60% !important;
    background: #fff;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
    overflow: auto;
    min-width: unset;
  }
`

const StyledButton = styled.button`
  position: fixed;
  bottom: 35px;
  left: 0;
  right: 0;
  margin: 0 auto !important;
  text-transform: none;
  letter-spacing: normal;
  width: 150px;
  background: #812a37;
  padding: 1.1rem 2rem 1rem;
  line-height: 5px;
  color: #fff;
  text-transform: uppercase;
  z-index: 999999;

  @media (min-width: 991px) {
    display: none;
  }
`

const CloseButton = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;

  @media (min-width: 992px) {
    display: none;
  }
`

const WoningAanbodTemplate = ({
  data: {
    page,
    woningen: { edges: woningen },
  },
  pageContext,
  location,
}) => {
  const [activeFilters, setActiveFilters] = useState({
    plaats: [],
    type: [],
    soort: [],
    minPrice:
      location.state && location.state.search
        ? parseInt(location.state.search.startPrice)
        : 0,
    maxPrice:
      location.state && location.state.search
        ? parseInt(location.state.search.endPrice)
        : 0,
    huur:
      location.state &&
      location.state.search &&
      location.state.search.type === 'rent'
        ? true
        : false,
    koop:
      location.state &&
      location.state.search &&
      location.state.search.type === 'buy'
        ? true
        : false,
    adres:
      location.state && location.state.search
        ? location.state.search.adres
        : '',
    range: '100',
  })

  let isFilterOpen = false

  const chunk = (array, chunkSize) => {
    const R = []
    for (let i = 0; i < array.length; i += chunkSize) {
      R.push(array.slice(i, i + chunkSize))
    }
    return R
  }

  const filterCampagne = ({ node: woning }) => {
    let serviceMatches = false
    let typeMatches = false
    let soortMatches = false
    let minPriceMatches = false
    let maxPriceMatches = false
    let huurMatches = false
    let koopMatches = false
    let cityMatches = false

    if (activeFilters.plaats.length > 0) {
      if (activeFilters.plaats.includes(woning.recapWoning.city)) {
        serviceMatches = true
      }
    } else {
      serviceMatches = true
    }

    if (activeFilters.type.length > 0) {
      if (activeFilters.type.includes(woning.metrecapWoning.typeOfHouse)) {
        typeMatches = true
      }
    } else {
      typeMatches = true
    }

    if (activeFilters.soort.length > 0) {
      if (activeFilters.soort.includes(woning.recapWoning.typeHouse)) {
        soortMatches = true
      }
    } else {
      soortMatches = true
    }

    if (activeFilters.minPrice > 0) {
      if (
        parseInt(activeFilters.minPrice) <= parseInt(woning.recapWoning.price)
      ) {
        minPriceMatches = true
      }
    } else {
      minPriceMatches = true
    }

    if (activeFilters.maxPrice > 0) {
      if (
        parseInt(activeFilters.maxPrice) >= parseInt(woning.recapWoning.price)
      ) {
        maxPriceMatches = true
      }
    } else {
      maxPriceMatches = true
    }

    if (activeFilters.huur !== false) {
      if (activeFilters.huur === woning.recapWoning.huur) {
        huurMatches = true
      }
    } else {
      huurMatches = true
    }

    if (activeFilters.koop !== false) {
      if (activeFilters.koop === woning.recapWoning.koop) {
        koopMatches = true
      }
    } else {
      koopMatches = true
    }

    if (activeFilters.adres !== '') {
      if (woning.recapWoning.city.toUpperCase().includes(activeFilters.adres.toUpperCase())) {
        cityMatches = true
      }

      // also return true if the adres looks like woning.recapWoning.address
      if (
        woning.recapWoning.address
          .toUpperCase()
          .includes(activeFilters.adres.toUpperCase())
      ) {
        cityMatches = true
      }
    } else {
      cityMatches = true
    }

    return (
      serviceMatches &&
      typeMatches &&
      soortMatches &&
      minPriceMatches &&
      maxPriceMatches &&
      huurMatches &&
      koopMatches &&
      cityMatches
    )
  }

  const backupWoningen = woningen

  const triggerFilter = () => {
    let filtered = backupWoningen.filter(filterCampagne)

    if (
      filtered.length !== 0 &&
      typeof filtered[0].node.currentRange !== 'undefined'
    ) {
      filtered = filtered.sort(
        (a, b) => a.node.currentRange - b.node.currentRange
      )
    }

    return filtered
  }

  const visibleWoningen = triggerFilter()

  const loadFilters = () => {
    const plaats = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.city !== null)
      .map(({ node: woning }) => woning.recapWoning.city)

    const type = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.type !== null)
      .map(({ node: woning }) => woning.recapWoning.type)

    const soort = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.typeHouse !== null)
      .map(({ node: woning }) => woning.recapWoning.typeHouse)

    const status = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.status !== null)
      .map(({ node: woning }) => woning.recapWoning.status)

    const minPrice = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.price !== null)
      .map(({ node: woning }) => woning.recapWoning.price)

    const maxPrice = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.price !== null)
      .map(({ node: woning }) => woning.recapWoning.price)

    const huur = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.huur !== false)
      .map(({ node: woning }) => woning.recapWoning.huur)

    const koop = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.koop !== false)
      .map(({ node: woning }) => woning.recapWoning.koop)

    const adres = backupWoningen
      .filter(({ node: woning }) => woning.recapWoning.city !== null)
      .map(({ node: woning }) => woning.recapWoning.city.toLowerCase())

    return {
      // new Set() returns unique values
      plaats: [...new Set(plaats)],
      type: [...new Set(type)],
      soort: [...new Set(soort)],
      status: [...new Set(status)],
      minPrice: [...new Set(minPrice)],
      maxPrice: [...new Set(maxPrice)],
      huur: [...new Set(huur)],
      koop: [...new Set(koop)],
      adres: [...new Set(adres)],
    }
  }

  const filters = loadFilters()

  const toggleMobileFilter = () => {
    if (window.innerWidth >= 992) {
      return
    }

    if (isFilterOpen) {
      jQuery('body').removeClass('filter-is-open')
      jQuery('.product-filter').animate({ left: '-100%' }, 300)
      jQuery('#filter-mask').remove()
    } else {
      jQuery('body').addClass('filter-is-open')
      jQuery('.product-filter').animate({ left: '0' }, 300)
      jQuery('#product-filter').append('<div id="filter-mask"></div>')
    }

    isFilterOpen = !isFilterOpen
  }

  const setActiveFilter = (name, value) => {
    setActiveFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }))

    toggleMobileFilter()
  }

  const [chunkSize, setChunkSize] = useState(9)

  const updateChunk = () => {
    setChunkSize(chunkSize + 9)
  }

  // const [isOpen, setOpen] = useState(false)

  return (
    <Layout>
      {/* {() => ( */}
        <>
          <SEO seo={page.seo} />

          <Aanbod>
            <CustomBreadCrumb
              data={page}
              className="py-2"
              pageContext={pageContext}
            />
            <div className="container">
              <div className="row flex-wrap-reverse py-5">
                <StyledButton
                  className="product-grid-button--mobile"
                  onClick={() => toggleMobileFilter()}
                >
                  Filter
                </StyledButton>

                <Filters className="product-filter col-lg-3 px-3 pr-lg-3">
                  <CloseButton
                    role="button"
                    onClick={() => toggleMobileFilter()}
                    r
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  </CloseButton>
                  <InnerFilters className="color-background-light pt-lg-5 pb-2">
                    <div className="px-0 px-lg-4 col-12 d-flex justify-content-center">
                      <Paragraph className="text-uppercase font-weight-xl font-size-xl">
                        {visibleWoningen.length}
                      </Paragraph>
                    </div>
                    <div className="px-0 px-lg-4 col-12 mb-4 d-flex justify-content-center">
                      <Paragraph className="text-uppercase font-weight-xl">
                        {visibleWoningen.length > 1 ||
                        visibleWoningen.length === 0
                          ? `resultaten`
                          : `resultaat`}
                      </Paragraph>
                    </div>
                    <RangeFilter
                      className="px-0 px-lg-4 col-12 mb-4"
                      activeFilterType1={activeFilters.adres}
                      onChange1={(value) => setActiveFilter('adres', value)}
                    />
                    <SwitchFilter
                      className="px-0 px-lg-4 col-12 mb-4"
                      type1="KOOP"
                      type2="HUUR"
                      activeFilterType1={activeFilters.koop}
                      activeFilterType2={activeFilters.huur}
                      onChange1={(value) => setActiveFilter('koop', value)}
                      onChange2={(value) => setActiveFilter('huur', value)}
                      tag="koopHuurSwitch"
                      title="KOOP OF PRIJS"
                    />
                    <PriceFilter
                      className="px-lg-4 col-12 mb-4"
                      activeFilterType={activeFilters.minPrice}
                      onChange={(values) => setActiveFilter('minPrice', values)}
                      tag="minPrice"
                      title="MINIMUM PRIJS"
                    />
                    <PriceFilter
                      className="px-lg-4 col-12 mb-4"
                      activeFilterType={activeFilters.maxPrice}
                      onChange={(values) => setActiveFilter('maxPrice', values)}
                      tag="maxPrice"
                      title="MAXIMUM PRIJS"
                    />
                    <FilterCategory
                      className="px-lg-4 col-12"
                      taxonomy={filters.plaats}
                      activeFilterType={activeFilters.plaats}
                      onChange={(values) => setActiveFilter('plaats', values)}
                      tag="plaats"
                      title="PLAATS"
                    />
                    <FilterCategory
                      className="px-lg-4 col-12"
                      taxonomy={filters.soort}
                      activeFilterType={activeFilters.soort}
                      onChange={(values) => setActiveFilter('soort', values)}
                      tag="soort"
                      title="SOORT"
                    />
                  </InnerFilters>
                </Filters>
                <div className="col-lg-9 pl-lg-3">
                  {/* <ProtoContent data={page} /> */}
                  <div className="row">
                    {!chunk(visibleWoningen, chunkSize)[0] ? (
                      <div className="col-12 d-flex justify-content-center mt-5 py-5">
                        <Paragraph className="text-uppercase font-weight-xl font-size-xl">
                          Geen resultaten
                        </Paragraph>
                      </div>
                    ) : (
                      <>
                        {chunk(visibleWoningen, chunkSize)[0].map((woning) => (
                          <SingleHome
                            className="col-sm-6 col-lg-4 pb-4"
                            data={woning}
                            key={woning.node.databaseId}
                          />
                        ))}
                      </>
                    )}
                  </div>
                  <LoadMoreContainer className="d-flex justify-content-center">
                    {chunk(visibleWoningen, chunkSize).length > 1 && (
                      <Paragraph
                        className="color-background-contrast font-size-xm font-weight-xl color-text-light px-4 py-2 text-uppercase"
                        onClick={() => updateChunk()}
                      >
                        Laad meer
                      </Paragraph>
                    )}
                  </LoadMoreContainer>
                </div>
              </div>
            </div>
            {/* <CTABanner data={page} /> */}
          </Aanbod>
        </>
      {/* )} */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      pageWoningAanbod {
        bannerDefault {
          description
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
    woningen: allWpWoning(
      sort: { fields: recapWoning___publishDate, order: DESC }
    ) {
      edges {
        node {
          slug
          title
          databaseId
          recapWoning {
            zipcode
            woonkamerOppervlakte
            video
            typeOfHouse
            typeHouse
            type
            totaleWoonoppervlakte
            totaleOppervlakte
            status
            publishDate
            province
            price
            openForVisits
            numberSleeprooms
            numberRooms
            location
            koopconditie
            koop
            image
            huurconditie
            huur
            houseId
            gallery
            fieldGroupName
            description
            city
            country
            buildYear
            bathrooms
            address
          }
        }
      }
    }
  }
`

export default WoningAanbodTemplate
